<template>
  <div style="display: flex">
    <div v-if="imageUrl.length != 0">
      <div
        style="
          position: relative;
          display: inline-block;
          margin-right: 10px;
          background: #eee;
        "
        class="img-box"
        v-for="(item, index) in imageUrl"
        :key="index"
      >
        <el-image
          ref="avc"
          :preview-src-list="imageUrl"
          fit="contain"
          class="avatar"
          :src="item"
        >
          <svg
            slot="error"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 54 61"
            fill="#e5e4e4"
            style="
              width: 50px;
              height: 50px;
              margin-left: 24px;
              margin-top: 22px;
            "
          >
            <path
              d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
            />
          </svg>
        </el-image>
        <!-- <i
          class="el-icon-error"
          style="
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 1;
            font-size: 20px;
            color: #ee4d2d;
          "
          @click="delImg(index)"
        ></i> -->

        <div class="upload-btn">
          <i
            @click="qwe"
            class="el-icon-zoom-in"
            style="margin-right: 14px; cursor: pointer"
          ></i>
          <i
            class="el-icon-delete"
            style="cursor: pointer"
            @click="delImg(index)"
          ></i>
        </div>
      </div>
    </div>
    <el-upload
      v-if="imageUrl.length < imgLength"
      class="avatar-uploader"
      :action="action"
      :show-file-list="false"
      :headers="headers"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      name="imagelist"
      :accept="accept"
    >
      <i class="el-icon-plus avatar-uploader-icon" slot="trigger"></i>
    </el-upload>
  </div>
</template>
<script>
import { getAAuth, getSchool } from "@/utils/auth.js";
import env from "@/settings/env.js";
import { delFile } from "@/api/admin.js";
export default {
  name: "UploadFiles",
  data() {
    return {
      headers: {
        Authorization: getAAuth(),
        school: getSchool(),
      },
      // imageUrl: [],
      action: env.apiUrlPrefix + "manager/api/upload/files",
      id: "",
    };
  },
  props: {
    imgLength: { type: Number, default: 1 },
    accept: { type: String, default: ".jpg,.jpeg,.png" },
    imageUrl: { type: Array },
    urlList: { type: Array },
    video: { type: Array },
    ids: { type: Array },
    state: { type: Number },
  },
  mounted() {
    if (this.$props.accept == ".mp4,.avi") {
      this.action = env.apiUrlPrefix + "api/upload/video";
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    beforeAvatarUpload(file) {
      if (
        file.type != "video/mp4" &&
        file.type != "video/avi" &&
        file.type != "image/png" &&
        file.type != "image/jpeg" &&
        file.type != "image/jpg"
      ) {
        this.$message.error("文件格式错误");
        return false;
      }
      if (this.$props.accept == ".mp4,.avi") {
        if (file.size > "41943040") {
          this.$message.error("文件大小超过限制，最大支持40MB");
          return false;
        }
      }
      if (this.$props.accept == ".jpg,.jpeg,.png") {
        if (this.$props.state == 1) {
          if (file.size > 1024 * 1024 * 7) {
            this.$message.error("文件大小超过限制，不得大于7MB");
            return false;
          }
        }
        if (this.$props.state == 2) {
          if (file.size > 1024 * 1024 * 3) {
            this.$message.error("文件大小超过限制，不得大于3MB");
            return false;
          }
        }
      }
    },
    handleAvatarSuccess(res) {
      if (res.code == 1) {
        this.$message.success("上传成功");
        if (this.$props.accept == ".mp4,.avi") {
          this.$props.imageUrl.push(res.data.video_img);
          this.$props.video.push(res.data.url);
          this.$props.ids.push(res.data.id);

          this.$forceUpdate();
        } else {
          this.$props.imageUrl.push(res.data.url);
          this.$props.urlList.push(res.data.short_url);
          this.$props.ids.push(res.data.id);
          this.$forceUpdate();
        }
      } else {
        this.$message.error(res.message);
      }
    },
    delImg(index) {
      if (this.$props.accept == ".mp4,.avi") {
        // deleteVideo({
        //   file_id: this.$props.ids[index],
        // }).then((res) => {
        //   if (res.code == 1) {
        //     this.$props.imageUrl.splice(index, 1);
        //     this.$props.video.splice(index, 1);
        //     this.$props.ids.splice(index, 1);
        //   }
        // });
      } else {
        delFile({
          file_id: this.$props.ids[index],
        }).then((res) => {
     
          if (res.code == 1) {
            this.$props.imageUrl.splice(index, 1);
            this.$props.ids.splice(index, 1);
            this.$forceUpdate();
          }
        });
      }
    },
    qwe() {
      this.$refs.avc[0].showViewer = true;
    },
  },
};
</script>
<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #ee4d2d;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 96px;
  height: 96px;
  line-height: 96px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  color: #ee4d2d;
}
.avatar {
  width: 96px;
  height: 96px;
  display: block;
  border-radius: 6px;
  //   border: 1px dashed #d9d9d9;

  .el-icon-error {
    display: none;
  }
}
.img-box {
  .upload-btn {
    color: #fff;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    font-size: 20px;
  }
}
.img-box:hover .upload-btn {
  display: flex;
  background: rgba(0, 0, 0, 0.2);
}
// .img-box:hover:hover .avatar {
//   border-color: #ee4d2d;
// }
.img-box:hover:btn {
  border-color: #ee4d2d;
}
.img-box:hover .el-icon-error {
  display: block;
}
</style>